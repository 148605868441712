<template>
  <v-container fluid>
    <v-card flat outlined class="pa-4 pa-sm-6">
      <v-sheet
        class="mb-5 text-h6 d-flex justify-space-between align-center"
        color="transparent"
      >
        Log Details - {{ log.id }}
        <v-chip
          v-if="!loading"
          small
          dark
          class="mx-2"
          :color="log.driver_uuid == auth.uuid ? 'blue' : 'lime'"
          >{{ log.driver_uuid == auth.uuid ? "My Log" : "Ohters Log" }}</v-chip
        >
        <v-spacer></v-spacer>
        <v-chip
          v-if="!loading && log.edit_status != 'DISABLED'"
          small
          dark
          :color="
            log.edit_status == 'REQUEST_WAITING'
              ? 'orange'
              : log.edit_status == 'REQUEST_REJECTED'
              ? 'error'
              : 'green'
          "
          >{{
            log.edit_status == "REQUEST_WAITING"
              ? "Edit Access Waiting"
              : log.edit_status == "REQUEST_REJECTED"
              ? "Edit Access Rejected"
              : "Edit Access Granted"
          }}</v-chip
        >
      </v-sheet>
      <v-row class="ms-md-12" v-if="loading">
        <v-col cols="12" sm="6" md="4" v-for="i in 15" :key="i">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-sheet v-else-if="log" color="transparent">
        <v-row>
          <v-col cols="12" md="4" class="text-center">
            <v-card flat outlined>
              <v-img :src="log.image_binary_file" class="mb-1"></v-img>
              {{ $_formatTimestampX(log.image_meta) }}
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-calendar-blank-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ log.date }}</v-list-item-title>
                    <v-list-item-subtitle>Date</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-ticket-confirmation-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.vehicles.vehicle_no }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Vehicle No</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-account-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.staff.first_name + " " + log.staff.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Driver</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-briefcase-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.project_uuid ? log.projects.code : "Not Given" }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Project</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-note-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        log.project_ledger_uuid
                          ? log.project_ledgers.code
                          : "Not Given"
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Project Ledger</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-counter </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.total_km }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Total (km)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.dispatch_time }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Dispatch Time</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-counter </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.dispatch_km }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Dispatch Reading (km)</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-clock-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.return_time }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Return Time</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-list-item two-line>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-counter </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ log.return_km }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Return Reading (km)</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-divider class="mb-5"></v-divider>
                <v-sheet class="" color="transparent">
                  <v-sheet class="text-caption" color="transparent"
                    >Description</v-sheet
                  >
                  <p class="text--primary mb-0">
                    {{ log.description }}
                  </p>
                </v-sheet>
              </v-col>
              <v-col cols="12">
                <v-divider class="mb-5"></v-divider>
                <v-sheet class="text-caption" color="transparent"
                  >Checklist</v-sheet
                >
                <v-row class="mt-2 px-2">
                  <v-col
                    cols="6"
                    class="pt-0"
                    v-for="(item, i) in checklist"
                    :key="i + 'c'"
                  >
                    <v-checkbox
                      :label="item"
                      :value="item"
                      v-model="selectedCheckList"
                      color="primary"
                      hide-details
                      readonly
                      dense
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <v-sheet class="my-4" color="transparent">
                  <v-sheet class="text-caption" color="transparent"
                    >Defects</v-sheet
                  >
                  <p class="text--primary">
                    {{ log.defects ? log.defects : "No Defects" }}
                  </p>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        (auth.uuid == log.driver_uuid &&
          ($_checkPermission(
            auth.permissions,
            'Manage Vehicle Running Logs',
            'Delete'
          ) ||
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Update'
            ))) ||
        ($_checkPermission(
          auth.permissions,
          'Manage Vehicle Running Logs',
          'Overrule'
        ) &&
          auth.uuid != log.driver_uuid &&
          log.edit_status == 'REQUEST_WAITING')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent"> Actions </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 4" :key="i + 'vla'">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list v-else color="actionList">
        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Update'
            ) &&
            auth.uuid == log.driver_uuid &&
            ($_daysDifference(log.date) > -2 || log.edit_status == 'ENABLED')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Edit Log Entry</v-list-item-title>
            <v-list-item-subtitle>
              Modify the log entry in the IMSO database.
              <span class="d-none d-md-inline">
                This action will not maintain a history of changes.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="primary" outlined @click="editItem()">
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Update'
            ) &&
            auth.uuid == log.driver_uuid &&
            log.edit_status == 'DISABLED'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Request Edit Access</v-list-item-title>
            <v-list-item-subtitle>
              Submit a request for permission to edit the log entry in the IMSO
              database.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateEditStatusX('REQUEST_WAITING')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-page-next-outline </v-icon>
              Request
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Update'
            ) &&
            auth.uuid == log.driver_uuid &&
            log.edit_status == 'REQUEST_WAITING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Cancel Edit Access Request</v-list-item-title>
            <v-list-item-subtitle>
              Withdraw a pending request for edit access to the log entry.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateEditStatusX('DISABLED')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-close </v-icon>
              Cancel
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Update'
            ) &&
            auth.uuid == log.driver_uuid &&
            log.edit_status == 'REQUEST_REJECTED'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Resubmit Edit Access Request</v-list-item-title>
            <v-list-item-subtitle>
              Resubmit a request for edit access to the log entry after a
              previous request was canceled or rejected.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateEditStatusX('REQUEST_WAITING')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-page-next-outline </v-icon>
              Resubmit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Overrule'
            ) &&
            auth.uuid != log.driver_uuid &&
            log.edit_status == 'REQUEST_WAITING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Reject Edit Access Request </v-list-item-title>
            <v-list-item-subtitle>
              Deny a user’s request for permission to edit the log entry.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateEditStatusX('REQUEST_REJECTED')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-close </v-icon>
              Reject
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Overrule'
            ) &&
            auth.uuid != log.driver_uuid &&
            log.edit_status == 'REQUEST_WAITING'
          "
        >
          <v-list-item-content>
            <v-list-item-title>Grant Edit Access</v-list-item-title>
            <v-list-item-subtitle>
              Approve a user’s request and provide them with permission to edit
              the log entry.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateEditStatusX('ENABLED')"
              :loading="statusLoading"
            >
              <v-icon left> mdi-check </v-icon>
              Grant Access
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Running Logs',
              'Delete'
            ) && auth.uuid == log.driver_uuid
          "
        >
          <v-list-item-content>
            <v-list-item-title> Delete Log Entry</v-list-item-title>
            <v-list-item-subtitle>
              Permanently remove the log entry from the IMSO database.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Delete log dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this log? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteLog()"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import constants from "@/constants.json";
import {
  updateEditStatus,
  syncOfflineVehicleRunningChanges,
  deleteVehicleRunning,
} from "@/services/vehicleRunningService";
import { getMyVehicleRunning } from "@/services/myDataService";
import { getVehicleRunning } from "@/services/logsService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    statusLoading: false,
    deleteLoading: false,
    deleteDialog: false,
    checklist: constants.vehicleChecklist,
    selectedCheckList: [],
    log: {},
    loading: false,
  }),
  async created() {
    if (this.$route.query.mylog && this.$route.query.mylog == "true") {
      await this.getMyLog();
      this.selectedCheckList = JSON.parse(this.log.check_list);
    } else {
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle Running Logs",
          "Read"
        )
      ) {
        await this.getLog();
        this.selectedCheckList = JSON.parse(this.log.check_list);
      } else {
        this.goBack();
      }
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageMyVehicleRunningList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditVehicleRunning",
        params: { uuid: this.log.uuid },
        query: { bc: this.log.id },
      });
    },
    async updateEditStatusX(status) {
      this.statusLoading = true;
      let updateEditStatusReturn = await updateEditStatus(
        this.log.vehicle_uuid,
        {
          uuid: this.log.uuid,
          edit_status: status,
        }
      );
      this.statusLoading = false;
      if (updateEditStatusReturn == "success") {
        if (this.$route.query.mylog && this.$route.query.mylog == "true") {
          await this.getMyLog();
          this.selectedCheckList = JSON.parse(this.log.check_list);
        } else {
          if (
            this.$_checkPermission(
              this.auth.permissions,
              "Manage Vehicle Running Logs",
              "Read"
            )
          ) {
            await this.getLog();
            this.selectedCheckList = JSON.parse(this.log.check_list);
          }
        }
      }
    },
    async getMyLog() {
      this.loading = true;
      await syncOfflineVehicleRunningChanges();
      this.log = await getMyVehicleRunning(this.$route.params.uuid);
      this.loading = false;
    },
    async getLog() {
      this.loading = true;
      this.log = await getVehicleRunning(this.$route.params.uuid);
      this.loading = false;
    },

    async deleteLog() {
      this.deleteLoading = true;
      let deleteReturn = await deleteVehicleRunning(this.log.vehicle_uuid, {
        uuid: this.log.uuid,
      });
      this.deleteLoading = false;
      if (deleteReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
  },
};
</script>